import { useState, useEffect } from "react";

export const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export function useImage(url: string) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    getBase64FromUrl(url).then((dataURL) => setImage(dataURL as any));
  }, [url]);

  return image;
}
