import { Box, Button, Flex, Title } from "@mantine/core";
import { useLocalStorage, usePrevious } from "@mantine/hooks";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  CCPro,
  appleBindAPI,
  getMeAPI,
  googleBindAPI,
  listSubscriptionsAPI,
  microsoftBindAPI,
  subscribePremiumAPI,
  subscribeStandardAPI,
  subscribeStatusAPI,
  unbindAPI,
  updateMeAPI,
} from "../Api";
import { AuthContext } from "../Auth";
import AppleSSO from "../components/AppleSSO";
import Footer from "../components/Footer";
import GoogleSSO from "../components/GoogleSSO";
import Header from "../components/Header";
import MicrosoftSSO from "../components/MicrosoftSSO";
import SettingForm from "../components/SettingForm";
import { useNavigate } from "react-router-dom";

enum BundleId {
  CCPro = "CCPro",
  CCStandard = "CCStandard",
}

// Import the Node type from the @types/react package
const isDev = process.env.REACT_APP_APP_ENV !== "production";

export const Settings = () => {
  const navigate = useNavigate();
  const { accessToken, setToken, removeToken } = useContext(AuthContext);
  const previousAuth = usePrevious(accessToken);
  const [settings, setSettings] = useState({});
  const [bundleId, setBundleId] = useState<BundleId | string>("");
  const [saves, setSaves] = useLocalStorage({
    key: "saves",
    defaultValue: {},
    getInitialValueInEffect: false,
    serialize: (value: any) => {
      /* return value serialized to string */
      console.log("serialize", value);
      return JSON.stringify(value);
    },
    deserialize: (sessionStorageValue: string) => {
      /* parse sessionStorage string value and return value */
      console.log("deserialize", sessionStorageValue);
      return JSON.parse(sessionStorageValue);
    },
  });
  const [licenses, setLicenses, removeLicenses] = useLocalStorage({
    key: "licenses",
    defaultValue: [],
    getInitialValueInEffect: true,
    serialize: (value: any) => {
      /* return value serialized to string */
      console.log("licenses serialize", value);
      return JSON.stringify(value);
    },
    deserialize: (sessionStorageValue: string) => {
      /* parse sessionStorage string value and return value */
      console.log("licenses deserialize", sessionStorageValue);
      return JSON.parse(sessionStorageValue);
    },
  });

  const fetchSettings = useCallback(async () => {
    const response = await getMeAPI(accessToken);

    if (response.status && response.data) {
      const { data: me } = response;
      setSettings(me);
    } else {
      console.log("Error retrieving settings:");
    }
  }, [accessToken, setSettings]);

  const handleClicked = () => {
    removeLicenses();
    removeToken();
  };

  useEffect(() => {
    if (accessToken) fetchSettings();
  }, [accessToken, fetchSettings]);

  const googleBind = async () => {
    const response = await googleBindAPI(accessToken);
    const { status, url } = (response as { status: any; url: any }) || {};
    if (status) {
      window.location = url;
    } else {
      console.log("Invalid response or missing status");
    }
  };
  const appleBind = async () => {
    const response = await appleBindAPI(accessToken);
    const { status, url } = (response as { status: any; url: any }) || {};
    if (status) {
      window.location = url;
    } else {
      console.log("Invalid response or missing status");
    }
  };

  const microsoftBind = async () => {
    const formData = {};
    const { status, url } = await microsoftBindAPI(accessToken, formData);
    if (status) {
      window.location = url;
    }
  };

  const unbind = (source: any) => async () => {
    const { email } = settings as any;

    await unbindAPI(accessToken, JSON.stringify({ source, email })).then(
      (data) => {
        if (data.status) setSettings(data.data);
      }
    );
  };

  const subscribePremium = async () => {
    if (bundleId === BundleId.CCPro) return;
    const returnUrl = `${process.env.REACT_APP_PUBLIC_URL}/settings`;
    const subscription = await subscribePremiumAPI(accessToken, returnUrl);
    if (subscription.status) {
      const { data } = subscription;
      if (data?.paymentUrl) {
        window.location = data.paymentUrl;
      }
    }
  };
  // const returnUrl =
  //   "http://localhost:3000/settings?token=eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQyBMayIsInN1YiI6ImY1ZGU3ZWY2LTA1MWItNDRhZi1iNTI2LWZlMmMwMTMzMTQ4OSIsImVtYWlsIjoiY2hpZW5saWtvNjZAZ21haWwuY29tIiwiZGlwbGF5TmFtZSI6IkMgTGsiLCJqdGkiOiJlNzE2MTY2NS1hNWQ3LTRhM2UtOGI2Yy0wZGIxNDE3YmM0OWYiLCJpYXQiOjE2ODU5MzA2ODY3MjMsImV4cCI6MTY4NTkzMzI3ODcyM30.iz8LarUxw72DZy2RiUoWRISxdVKiJCFw0ej3l5o9LfWoUixwThlotWG12s-Sq5SESgKQGMk578c7yq6SPvz_Xw";

  const subscribeStandard = async () => {
    if (bundleId === BundleId.CCStandard) return;
    const returnUrl = `${process.env.REACT_APP_PUBLIC_URL}/settings`;
    const subscription = await subscribeStandardAPI(accessToken, returnUrl);
    if (subscription.status) {
      const { data } = subscription;
      if (data?.paymentUrl) {
        window.location = data.paymentUrl;
      }
    }
  };

  const subscribeStatus = async () => {
    const returnUrl = `${process.env.REACT_APP_PUBLIC_URL}/settings`;
    if (bundleId === BundleId.CCPro) return;
    const { data, status } = (await subscribeStatusAPI(
      accessToken,
      returnUrl
    )) as any;
    console.log(data);
    if (status) {
      data.data?.url && (window.location = data.data.url);
    }
  };

  const listSubscriptions = useCallback(async () => {
    const response: any = await listSubscriptionsAPI(accessToken);
    const { status, data } = response;
    if (status && data.length > 0) setBundleId(data.shift());
  }, [accessToken]);

  const handleSubmit = async (form: any, data: any) => {
    console.log("handleSubmit", data);
    const response = await updateMeAPI(accessToken, {
      email: data.email,
    });
    if (response.status) {
      setSaves(data);
    } else {
      const { message } = response;
      form.setErrors({ email: message });
    }
  };

  useEffect(() => {
    if (accessToken) listSubscriptions();
  }, [accessToken, listSubscriptions]);

  // const { email, hasApple, hasGoogle, hasMS, id } = settings;
  return (
    <div className="settings-container">
      <Header>
        <button className="login-button" onClick={handleClicked}>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/icon_exit_2.png`}
            alt="Back"
          />
        </button>
      </Header>
      <div className="content">
        <SettingForm settings={settings as any} onSubmit={handleSubmit}>
          <GoogleSSO
            account={settings}
            bind={googleBind}
            unbind={unbind("google")}
          />
          <AppleSSO
            account={settings}
            bind={appleBind}
            unbind={unbind("apple")}
          />
          <MicrosoftSSO
            account={settings}
            bind={microsoftBind}
            unbind={unbind("microsoft")}
          />
        </SettingForm>
        {isDev ? (
          <Box maw={600} mx="auto" className="subscribe">
            <Title order={3} weight={500} style={{ color: "#fff" }}>
              Subscription
            </Title>
            <Flex m={0} justify="space-between" direction="row" wrap="wrap">
              <Button
                className="premium"
                variant={bundleId === CCPro ? "filled" : "outline"}
                size="lg"
                onClick={subscribePremium}
                disabled={bundleId ? true : false}
                style={{ color: "#00c7b1", borderColor: "#00c7b1" }}
              >
                Premium
              </Button>
              <Button
                className="premium"
                variant={bundleId === CCPro ? "filled" : "outline"}
                size="lg"
                onClick={subscribeStandard}
                disabled={bundleId ? true : false}
                style={{ color: "#00c7b1", borderColor: "#00c7b1" }}
              >
                Standard
              </Button>
            </Flex>
            <Button
              className="status"
              size="lg"
              onClick={subscribeStatus}
              disabled={bundleId ? true : false}
              variant="outline"
              style={{ color: "#00c7b1", borderColor: "#00c7b1" }}
            >
              Subscription Status
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </div>
  );
};
