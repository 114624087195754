import React from "react";
import {
  Title,
  Text,
  TextInput,
  Checkbox,
  Button,
  Group,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState } from "@mantine/hooks";

interface Settings {
  email: string;
  name?: string;
}

interface SettingFormProps {
  settings: Settings;
  children: React.ReactNode;
  onSubmit: (form: any, values: any) => void;
}

const SettingForm: React.FC<SettingFormProps> = ({
  settings,
  children,
  onSubmit,
}) => {
  // TODO get props from real settings
  console.log("SettingForm", settings);
  const { email, name } = settings;
  const username = name ?? email?.split("@")[0];
  const form = useForm({
    initialValues: {
      email: "",
      termsOfService: false,
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const tosText = (
    <Text span inherit c="#71c5e8">
      terms and conditions of use
    </Text>
  );

  return (
    <Box maw={600} mx="auto">
      <Title order={2} weight={700} color="#fff" ta="center">
        Your Creactive Cast Membership Settings
      </Title>
      <TextInput
        size="lg"
        variant="filled"
        label="Username"
        placeholder={username}
        disabled
        styles={{ label: { color: "#00c7b1" } }}
      />
      <form
        onSubmit={form.onSubmit((values) => {
          console.log(values);
          onSubmit(form, values);
        })}
      >
        <TextInput
          size="lg"
          withAsterisk
          label="Email"
          placeholder={settings?.email}
          styles={{ label: { color: "#00c7b1" } }}
          // value={settings.email}
          {...form.getInputProps("email")}
        />

        {children}

        <Checkbox
          size="lg"
          mt="md"
          disabled
          label={<Text>I agree with {tosText}</Text>}
          checked
          // {...form.getInputProps("termsOfService", { type: "checkbox" })}
        />

        <Group position="right" mt="md">
          <Button disabled={!form.isDirty()} type="submit">
            Save Change
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default SettingForm;
