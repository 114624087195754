import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import AuthProvider from "./Auth";
import { CreativeCast } from "./routes/CreativeCast";
import { Passport } from "./routes/Passport";
import { Settings } from "./routes/Settings";
import { ProtectedRoute } from "./ProtectedRoute";
import "./App.css";

export const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <CreativeCast />,
      errorElement: <CreativeCast />,
    },
    {
      path: "/creativecast",
      element: <CreativeCast />,
    },
    {
      path: "/passport",
      element: <Passport />,
    },
    {
      path: "/settings",
      element: (
        <ProtectedRoute>
          <Settings />,
        </ProtectedRoute>
      ),
    },
  ]);

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <RouterProvider router={router} />
    </MantineProvider>
  );
};
