import React, { useEffect, useRef, useState } from "react";
import PinCodeDisplay from "../components/PinCodeDisplay";

export const Passport: React.FC = () => {
  const [code, setCode] = useState<number[]>([]);
  const dataFetchedRef = useRef<boolean>(false);

  const fetchPassport = async (): Promise<void> => {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const hash = urlParams.get("hash") || "";

    if (hash.length === 0) {
      throw new Error("No hash provided");
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/passport/${hash}`
    );

    if (response.status === 200) {
      const json = (await response.json()) as { code: string };
      setCode(json.code.split("").map((str) => Number(str)));
    } else {
      throw new Error();
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchPassport().catch(() => {
      window.location.href = "/";
      return;
    });
  }, []);

  return (
    <div className="pincode-container">
      <h1>Your Creative Cast Membership Passport</h1>
      <PinCodeDisplay code={code} />
    </div>
  );
};
