const BASE_URL = process.env.REACT_APP_API_URL + "/api"; // "https://dz6j7saysn63y.cloudfront.net/api";

const responseParser = async (response: any) => {
  const status = response.status === 200;
  let json = {};
  try {
    json = await response.json();
  } catch (e) {}

  if (status) {
    return {
      status,
      result: ("result" in json && json.result) || json,
    };
  }

  return {
    status,
    message: "message" in json && json.message,
  };
};

export const getPincodeAPI = async (formData: any) => {
  const { email, username, deviceId } = formData;
  const response = await fetch(`${BASE_URL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, username, deviceId }),
  });

  return responseParser(response);
};

export const verifyAPI = async (formData: any) => {
  const { email, passport } = formData;
  const response = await fetch(`${BASE_URL}/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, passport }),
  });

  const payload = await responseParser(response);
  if (payload.status) {
    return {
      status: payload.status,
      token: "result" in payload && (payload.result as { token: string }).token,
    };
  }
  return payload;
};

export const getMeAPI = async (token: string) => {
  const response = await fetch(`${BASE_URL}/user/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  const payload = await responseParser(response);
  if (payload.status) {
    return {
      status: payload.status,
      data: "result" in payload && (payload.result as { data: {} }).data,
    };
  }
  return payload;
};

export const updateMeAPI = async (token: any, formData: any) => {
  const { email } = formData;
  const response = await fetch(`${BASE_URL}/user/me`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ email }),
  });
  const payload = await responseParser(response);
  if (payload.status) {
    return {
      status: payload.status,
    };
  }
  return payload;
};

export const googleLoginAPI = async () => {
  const response = await fetch(`${BASE_URL}/user/google-login`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
};

export const googleBindAPI = async (token: string) => {
  const response = await fetch(`${BASE_URL}/user/google-signin`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  const json = await response.json();
  return json;
};

export const appleBindAPI = async (token: string) => {
  const response = await fetch(`${BASE_URL}/user/apple-signin`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  const json = await response.json();
  return json;
};

export const microsoftLoginAPI = async (formData: any) => {
  // const {  } = formData;
  const response = await fetch(`${BASE_URL}/user/microsoft-login`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const payload = await responseParser(response);
  if (payload.status) {
    return {
      status: payload.status,
      url: (payload.result as { url: any }).url,
    };
  }
  return payload;
};

export const microsoftBindAPI = async (token: any, formData: any) => {
  const response = await fetch(`${BASE_URL}/user/microsoft-signin`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  const json: any = await response.json();
  return json;
};

export const unbindAPI = async (token: any, formData: any) => {
  // const { id, email, unbind } = formData;
  console.log("unbindAPI", token, formData);
  const response = await fetch(`${BASE_URL}/user/unbind`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: formData,
  });

  const payload = await responseParser(response);
  if (payload.status) {
    return {
      status: payload.status,
      data: (payload.result as { data: any }).data,
    };
  }
  return payload;
};

export const unbindGoogleftAPI = async (formData: any) => {
  return {};
};

export const unbindAppleAPI = async (formData: any) => {
  return {};
};

export const unbindMicrosoftAPI = async (formData: any) => {
  return {};
};

export const CCPro = "5eb56628-2a82-4920-879e-c31ce22094cd";
export const CCStandard = "8dcf1dfb-9888-411e-aba0-1856e5a5caea";
export const listSubscriptionsAPI = async (token: any) => {
  if (!token) {
    return;
  }
  const response = await fetch(`${BASE_URL}/user/subscribe`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  return json;
};

export const subscribePremiumAPI = async (token: any, returnUrl: string) => {
  const response = await fetch(`${BASE_URL}/user/subscribe/${CCPro}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ returnUrl }),
  });

  const payload = await responseParser(response);
  if (payload.status) {
    return {
      status: payload.status,
      data: (payload.result as { data: any }).data,
    };
  }
  return payload;
};

export const subscribeStatusAPI = async (token: string, returnUrl: string) => {
  const response = await fetch(`${BASE_URL}/user/portal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ returnUrl }),
  });

  const payload = await responseParser(response);
  if (payload.status) {
    return {
      status: payload.status,
      data: payload.result as { data: string }, //.data,
    };
  }
  return payload;
};

export const subscribeStandardAPI = async (token: any, returnUrl: string) => {
  const response = await fetch(`${BASE_URL}/user/subscribe/${CCStandard}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ returnUrl }),
  });

  const payload = await responseParser(response);
  if (payload.status) {
    return {
      status: payload.status,
      data: (payload.result as { data: any }).data,
    };
  }
  return payload;
};
