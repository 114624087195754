import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth";
import LoginForm from "../components/LoginForm";
import ListItem from "../components/ListItem";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useImage } from "../utils";
import { Grid } from "@mantine/core";

// Import the Node type from the @types/react package

const useOnClickOutside = (
  ref: React.RefObject<HTMLDivElement>,
  handler: (ev: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const listener = (ev: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendant elements
      if (!ref.current || ref.current.contains(ev.target as Node)) {
        return;
      }
      handler(ev);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const CreativeCast = () => {
  const { accessToken, setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const ccIcon: any = useImage(
    `${process.env.REACT_APP_PUBLIC_URL}/icon_cc.svg`
  );
  const mobileImg: any = useImage(
    `${process.env.REACT_APP_PUBLIC_URL}/mobile.png`
  );
  const webImg: any = useImage(`${process.env.REACT_APP_PUBLIC_URL}/web.png`);
  const featureImg: any = useImage(
    `${process.env.REACT_APP_PUBLIC_URL}/feature.png`
  );
  const byodImg: any = useImage(`${process.env.REACT_APP_PUBLIC_URL}/byod.jpg`);
  const icon = (
    <object
      type="image/svg+xml"
      data={ccIcon}
      className="x-icon"
      aria-label="CC"
    />
  );
  const mobileIcon = (
    <object
      type="image/png"
      data={mobileImg}
      className="x-icon"
      aria-label="mobile"
    />
  );
  const webIcon = (
    <object
      type="image/png"
      data={webImg}
      className="x-icon"
      aria-label="web"
    />
  );
  const featureIcon = (
    <object
      type="image/png"
      data={featureImg}
      className="x-icon"
      aria-label="feature"
    />
  );
  const byod = <object type="image/jpg" data={byodImg} aria-label="byod" />;
  // Create a ref that we add to the element for which we want to detect outside clicks
  const loginRef = useRef<HTMLDivElement>(null);
  const cookieAppend = useRef(false);

  // State for our modal
  const [isModalOpen, setModalOpen] = useState(false);

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(loginRef, () => setModalOpen(false));

  const handleLoginClicked = () => {
    if (accessToken) {
      console.log("Button clicked", accessToken);
      navigate("/settings");
    } else {
      setModalOpen(true);
    }
  };

  const onChangeAuth = (value: string) => {
    setToken(value);
    // sessionStorage.setItem("accessToken", value);
    console.log(accessToken);
    navigate("/settings");
  };

  useEffect(() => {
    if (cookieAppend.current) return;
    cookieAppend.current = true;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.optoma.com/wp-content/themes/optoma-corporate-v2/assets/javascript/cookie.base.js?ver=1.9.15";
    document.body.appendChild(script);
  }, []);

  return (
    <div className="app-container">
      <Header>
        <button className="login-button" onClick={handleLoginClicked}>
          {accessToken ? "Setting" : "Login"}
        </button>
      </Header>
      <div className="list-container">
        {/* <div className="list-content">
          {platformList.map((item, index) => (
            <ListItem
              key={index}
              icon={item.img}
              subject={item.subject}
              description={item.description}
              url={item.download}
            />
          ))}
        </div> */}
        <div className="list">
          <div className="overlay-content">
            <div className="cc_overlay">
              <div className="cc_icon">
                {icon}
                <div className="cc_download">
                  <a href="https://creative-cast.optoma.com/">
                    Creative Cast APP Download
                  </a>
                </div>
              </div>
              <div className="cc_intro">
                <p className="cc_title">Welcome to Creative Cast</p>
                <p className="cc_description">
                  Connect any device. Wirelessly broadcast, share or stream your
                  content to the big screen with Creative Cast Pro™. Screen
                  mirror high-quality images, video, documents and audio from
                  your smartphone, laptop or PC - all with a touch of a button.
                  Bringing your own device has never been easier. Designed for
                  both education and business settings - up to 40 devices can be
                  connected simultaneously and users can annotate and save still
                  images from any presentation.
                </p>
              </div>
            </div>
            <div className="cc_version">beta</div>
          </div>
        </div>
        <div className="list">
          <div className="cc_card">
            <div className="card">
              <div className="card_content">
                <h1>Creative Cast</h1>
                <h2>Creative Cast is Optoma Wireless Collaboration solution</h2>
                <Grid className="grid">
                  <Grid.Col className="mobile" span={4}>
                    {mobileIcon}
                    <h2>APP CASTING</h2>
                    <h4>HIGH-FIDELITY MIRRORING & SHARING ON ANY DEVICE</h4>
                    <h4>SIMPLE CONNECTION IN COMPLEX</h4>
                    <h4>MULTI-PROJECTOR ENVIRONMENTS</h4>
                  </Grid.Col>
                  <Grid.Col className="web" span={4}>
                    {webIcon}
                    <h2>APP-LESS CASTING</h2>
                    <h4>APPLE DEVICES BUILT-IN </h4>
                    <h4>NO APP NO PROBLEM</h4>
                  </Grid.Col>
                  <Grid.Col className="feature" span={4}>
                    {featureIcon}
                    <h2>FUN & USEFUL FEATURES</h2>
                    <h4>SMART CONTROL + REMOTE KEYBOARD</h4>
                    <h4>LIVE BROADCAST ONTO THE GIANT SCREEN</h4>
                  </Grid.Col>
                </Grid>
              </div>
            </div>
            <div className="card">{byod}</div>
          </div>
        </div>
        <div className="list">
          <div className="byod">
            <div className="byod_content">
              {byod}
              <div className="byod_description">
                <h1>ENABLING BRING YOUR OWN DEVICE (BYOD) MEETINGS</h1>
                <h2>
                  All major platforms supported: Windows, Mac, Chrome, Android
                  and iOS.
                </h2>
                <h4>
                  Conduct meetings using the device and software you're most
                  familiar with, whether it's on your own notebook, tablet or
                  smartphone. Mirror your device wirelessly to the display using
                  Creative Cast Pro or connect using a cable.
                </h4>
                <h4>
                  When connecting a notebook or desktop computer, the display
                  can either mirror your screen or function as a second display.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {true ? (
        isModalOpen &&
        !accessToken && (
          <div className="modal">
            <div className="modal-content" ref={loginRef}>
              <LoginForm onChange={onChangeAuth} />
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
