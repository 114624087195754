import { useLocalStorage } from "@mantine/hooks";
import React, { createContext, ReactNode, useEffect, useState } from "react";

export const AuthContext = createContext<{
  accessToken: string;
  setToken: (val: string) => void;
  removeToken: () => void;
}>({
  accessToken: "",
  setToken: () => {},
  removeToken: () => {},
});

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [isAuth, setAuth, removeAuth] = useLocalStorage<string>({
    key: "isAuth",
    defaultValue: undefined,
    getInitialValueInEffect: false,
    serialize: (value) => {
      return JSON.stringify(value);
    },
    deserialize: (sessionStorageValue) => {
      try {
        return JSON.parse(sessionStorageValue);
      } catch {
        return null;
      }
    },
  });

  const url = new URL(window.location.href);
  if (url) {
    const token = url.searchParams.get("token");
    url.searchParams.delete("token");
    if (token) {
      window.history.replaceState({}, "", url.href);
      setAuth(token);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken: isAuth,
        setToken: setAuth,
        removeToken: removeAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export const useAuth = () => React.useContext(AuthContext);
