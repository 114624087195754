import React, { MouseEvent } from "react";
import { useImage } from "../utils";
import Binding from "./Binding";
import "./AppleSSO.css";
import { IAppleSSO } from "./type";

type AppleSSOProps = {
  account: {
    email?: string;
    hasApple?: boolean;
    appleEmail?: string;
    hasMS?: boolean;
    id?: string;
    isSSO?: boolean;
    ssoFrom?: string;
  };
  bindUrl?: string;
  bind?: () => void;
  unbind?: () => void;
};

const AppleSSO = ({ account, bindUrl, bind, unbind }: AppleSSOProps) => {
  const appleIcon: any = useImage(
    `${process.env.REACT_APP_PUBLIC_URL}/appleSSO.svg`
  );

  const icon = (
    <object
      type="image/svg+xml"
      data={appleIcon}
      className="x-icon"
      aria-label="Apple"
    />
  );

  const handleBind = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (bind) await bind();
  };

  const handleUnbind = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (unbind) await unbind();
  };

  const { email, hasApple, appleEmail, hasMS, id, isSSO, ssoFrom } = account;

  return (
    <div className="apple">
      {hasApple ? (
        <Binding
          icon={icon}
          onClick={handleUnbind}
          canUnbind={!(ssoFrom === "Apple" && isSSO)}
        >
          {appleEmail}
        </Binding>
      ) : bindUrl ? (
        <a id="apple-signin" href={bindUrl} target="_self">
          {icon}
          Sign in with Apple
        </a>
      ) : (
        <div id="apple-signin" onClick={handleBind}>
          {icon}
          Sign in and bind with Apple
        </div>
      )}
    </div>
  );
};

export default AppleSSO;
