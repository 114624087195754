import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mantine/core";
import {
  getPincodeAPI,
  googleLoginAPI,
  microsoftLoginAPI,
  verifyAPI,
} from "../Api";

import AppleSSO from "./AppleSSO";
import GoogleSSO from "./GoogleSSO";
import MicrosoftSSO from "./MicrosoftSSO";

import "./LoginForm.css";

enum Stage {
  REQUEST = "request",
  VERIFY = "verify",
}

const LoginForm: React.FC<{ onChange?: (token: string) => void }> = ({
  onChange = () => {},
}) => {
  const navigate = useNavigate();
  const [stage, setStage] = useState<Stage>(Stage.REQUEST);
  const [email, setEmail] = useState("");
  const [pincode, setPincode] = useState("");

  // Reset pincode input when stage changes
  useEffect(() => {
    setPincode("");
  }, [stage]);

  const getPincode = async () => {
    const username = email.split("@")[0];
    const response = await getPincodeAPI({ username, email });
    if (response.status) setStage(Stage.VERIFY);
    else {
      console.log("error", response.message);
    }
  };

  const verify = async () => {
    const response = await verifyAPI({ email, passport: pincode }).catch(
      (error) => error
    );
    // Passport expired
    if (!response.status) setStage(Stage.REQUEST);
    else {
      onChange(response.token);
    }
  };

  const handleSubmit =
    stage === Stage.REQUEST ? (
      <CCSubmitButton text="Get Pincode" onClick={getPincode} />
    ) : (
      <CCSubmitButton text="Login" onClick={verify} />
    );

  return (
    <div className="login-form-container">
      <h2>Sign in</h2>
      <CCInputField
        type="text"
        placeholder="Email"
        value={email}
        onChange={setEmail}
      />
      <CCInputField
        className={stage === Stage.REQUEST ? "hidden" : ""}
        placeholder="PinCode"
        value={pincode}
        type={""}
        onChange={setPincode}
      />
      {handleSubmit}
      <Divider my="xl" size="sm" label="Or" labelPosition="center" />
      <GoogleSSO
        account={{}}
        bindUrl={`${process.env.REACT_APP_API_URL}/api/user/google-login`}
      />
      <AppleSSO
        account={{}}
        bindUrl={`${process.env.REACT_APP_API_URL}/api/user/apple-login`}
      />
      <MicrosoftSSO
        account={{}}
        bindUrl={`${process.env.REACT_APP_API_URL}/api/user/microsoft-login`}
      />
    </div>
  );
};

const CCInputField: React.FC<{
  className?: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}> = ({ className, type, placeholder, value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <input
      className={classNames("input-field", className)}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
    />
  );
};

const CCSubmitButton: React.FC<{ text: string; onClick: () => void }> = ({
  text,
  onClick,
}) => {
  console.log("Button rendered");
  return (
    <div className="submit-container">
      <button className="submit-button" onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

export default LoginForm;
